<template>
  <div>
    <div class="pathway-display">
      <div class="container">
        <h1 class="pathway-title">{{ activeResource.title }}</h1>

        <div class="columns">
          <div class="column is-8 video-block">
            <figure class="has-ratio">
              <object
                class="video-object"
                :data="activeResource.source"
                frameborder="1"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></object>
            </figure>
          </div>
          <div class="column list-block">
            {{ pathway.resources.length }} Resources
            <ul>
              <li
                v-for="resource in pathway.resources"
                :key="resource.id"
                @click="setActiveResource(resource)"
              >
                <div class="columns is-mobile resource-flexbox">
                  <div class="column is-2">
                    <i
                      class="fas fa-2x"
                      :class="getResourceIconClass(resource)"
                    ></i>
                  </div>
                  <div class="column is-10">
                    <h3
                      class="resource-title"
                      :class="{
                        active: resource.id === activeResource.id,
                      }"
                    >
                      {{ resource.title }}
                    </h3>
                    <p class="resource-caption">{{ resource.caption }}</p>
                  </div>
                  <div class="column" style="font-size: 18px;">
                    <i
                      class="fas fa-lg"
                      :class="getResourceTypeIconClass(resource.resourceType)"
                    ></i>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="pathway-interact">
      <div class="container">
        <b-tabs v-model="activeTab">
          <b-tab-item label="About">
            <div class="about">
              <div>
                {{ pathway.summary }}
              </div>
              <div>
                <button class="button is-primary is-outlined">
                  <span> Save this Solution </span>
                  <span class="icon is-small">
                    <i class="fas fa-heart"></i>
                  </span>
                </button>
                <button class="button is-primary is-outlined">
                  <span> Get Advisory</span>
                  <span class="icon is-small">
                    <i class="fas fa-user"></i>
                  </span>
                </button>
              </div>
            </div>
          </b-tab-item>
          <b-tab-item label="Question Pad">
            <QuestionPad
              :currentQuestions="questionPad"
              :activeResource="activeResource"
            />
          </b-tab-item>
          <b-tab-item label="Discovery">
            Lorem ipsum dolor sit amet.
          </b-tab-item>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/_variables.scss";

.pathway-display {
  background-color: $black;
  margin-bottom: 4rem;

  .resource-flexbox {
    align-items: center;
  }

  .breadcrumbs {
    text-align: left;
  }
  .pathway-title {
    font-size: 40px;
    text-align: left;
    margin: 2rem 0 1rem;
  }

  .video-block {
    text-align: right;

    .has-ratio {
      height: 100%;
      .video-object {
        width: 100%;
        height: 100%;
      }
    }
  }

  .list-block {
    text-align: left;

    &:hover {
      cursor: pointer;
    }

    ul {
      margin: 1rem 0;
    }

    .resource-title {
      font-size: 20px;
      font-weight: 700;
    }

    .resource-caption {
      font-size: 14px;
    }
  }
}

.active {
  color: $primary;
}

.pathway-interact {
  background-color: $white;
  color: $black;
  text-align: left;

  .b-tabs {
    padding: 2rem 0;
  }

  .about {
    button {
      margin: 1rem 2rem 0rem 0;
    }
  }
}
</style>

<script>
import QuestionPad from "@/hoc/QuestionPad.vue";

export default {
  name: "Pathway",
  components: {
    QuestionPad,
  },
  data() {
    return {
      activeTab: 1,
      activeResource: {
        id: "youtube-dynamically-render-1929234",
        source: "https://www.youtube.com/embed/CSSGnTUPg60",
        title: "Dynamically rendering Vue",
        resourceType: "Hands On Video",
        caption: "Dynamically render components in VueJS as web components",
        isCompleted: true,
      },
      pathway: {
        title: "The Dank Coder Way of Javascript",
        summary: "Go from 0 to Competency in Javascript",
        resources: [
          {
            id: "youtube-dynamically-render-1929234",
            source: "https://www.youtube.com/embed/CSSGnTUPg60",
            title: "Another video",
            resourceType: "Hands on video",
            caption: "Dynamically render components in VueJS as web components",
            isCompleted: true,
          },
          {
            id: "youtube-dynamically-render-1929235",
            source: "https://www.w3schools.com/tags/tag_object.asp",
            title: "Pixel Perfect SCSS with Vue",
            resourceType: "Text Guide",
            caption: "Load SCSS into Vue components",
            isCompleted: true,
          },
          {
            id: "youtube-dynamically-render-19292358",
            source: "https://www.youtube.com/embed/NlovbLiuGLM",
            title: "Vue state management",
            resourceType: "Video Lecture",
            caption: "Dynamically manage your state with VueJS",
            isCompleted: false,
          },
          {
            id: "youtube-dynamically-render-1929239",
            source: "https://www.youtube.com/embed/NlovbLiuGLM",
            title: "Dynamically rendering Vue",
            resourceType: "Hands On Video",
            caption: "Dynamically render components in VueJS as web components",
            isCompleted: false,
          },
        ],
      },
      questionPad: [
        {
          id: "1",
          question: "How does Chrome vs Safari make a difference?",
          answers: [],
          references: {
            id: "youtube-dynamically-render-1929239",
            source: "https://www.youtube.com/embed/NlovbLiuGLM",
            title: "Dynamically rendering Vue",
          },
        },
      ],
      activeQuestions: [],
    };
  },
  methods: {
    getResourceTypeIconClass(resourceType) {
      return resourceType.toLowerCase().includes("video")
        ? "fa-video"
        : "fa-file-alt";
    },
    getResourceIconClass(resource) {
      if (resource.id === this.activeResource.id) {
        return "fa-arrow-alt-circle-left";
      }

      if (resource.isCompleted) {
        return "fa-check-circle";
      }

      return "fa-play-circle";
    },
    setActiveResource(resource) {
      this.activeResource = resource;
    },
  },
};
</script>
